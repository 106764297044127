<template>
  <ProjectDetails
    hide-summary-comments
    is-shared-link
    hide-remove-project
    hide-compare-views
    hide-versions
    hide-shared-link
    hide-documents
    hide-find-in-view
    hide-adding-new-row />
</template>
<script>
import ProjectDetails from '@/views/ProjectDetails';
export default {
  name: 'WrapperProjectSharedLink',
  components: {
    ProjectDetails,
  },
};
</script>
